<template>
  <div class="login">
    <!--顶部-->
    <div class="header">
      <!--暂时留空-->
    </div>
    <!--中间内容区-->
    <div class="container" style="margin-top: 50px">
      <div class="title">
        <div class="main-title">
          <div class="title-text">农产品检测监管系统</div>
        </div>
      </div>
      <div class="content" style="margin-top: 30px">
        <!--登录入口-->
        <!--普通登录-->
        <a-form :form="form" style="margin-top: 12px" @submit="handleLogin">
          <a-form-item>
            <a-input
              v-decorator="[
                'username',
                { rules: [{ required: true, message: '用户名不能为空' }] },
              ]"
              placeholder="用户名"
              size="large"
            >
              <a-icon
                slot="prefix"
                style="color: rgba(0, 0, 0, 0.25)"
                type="user"
              />
            </a-input>
          </a-form-item>
          <a-form-item style="margin-top: 12px">
            <a-input
              v-decorator="[
                'password',
                { rules: [{ required: true, message: '密码不能为空' }] },
              ]"
              placeholder="密码"
              size="large"
              type="password"
            >
              <a-icon
                slot="prefix"
                style="color: rgba(0, 0, 0, 0.25)"
                type="lock"
              />
            </a-input>
          </a-form-item>

          <a-form-item style="margin-top: 12px">
            <a-row>
              <a-col
                :lg="{ span: 14 }"
                :md="{ span: 14 }"
                :sm="{ span: 14 }"
                :xl="{ span: 14 }"
                :xs="{ span: 14 }"
              >
                <a-input
                  v-decorator="[
                    'code',
                    { rules: [{ required: true, message: '验证码不能为空' }] },
                  ]"
                  placeholder="验证码"
                  size="large"
                >
                  <a-icon
                    slot="prefix"
                    style="color: rgba(0, 0, 0, 0.25)"
                    type="picture"
                  />
                </a-input>
              </a-col>
              <a-col
                :lg="{ span: 8, offset: 2 }"
                :md="{ span: 8, offset: 2 }"
                :sm="{ span: 8, offset: 2 }"
                :xl="{ span: 8, offset: 2 }"
                :xs="{ span: 8, offset: 2 }"
              >
                <img
                  :src="captcha"
                  alt=""
                  style="width: 100%"
                  @click="getCaptchaImg"
                />
              </a-col>
            </a-row>
          </a-form-item>

          <a-form-item>
            <a-button
              block
              html-type="submit"
              size="large"
              style="margin-top: 16px"
              type="primary"
            >
              登录
            </a-button>
          </a-form-item>
        </a-form>
      </div>
      <div class="foot"></div>
    </div>
    <!--脚底下-->
    <div class="footer">
      <div class="text">
        Copyright
        <a-icon type="copyright" />
        2020 - {{ copyright }}
      </div>
    </div>
  </div>
</template>

<script>
import { getCaptcha, login } from "@/api/user";
import moment from "moment";

export default {
  name: "Login",
  data() {
    return {
      formLayout: "horizontal",
      form: this.$form.createForm(this),
      captcha: "", // 验证码base64
      validateStatus: "", // ‘success’, ‘warning’, ‘error’, ‘validating’
      help: "", // 提示信息
      hasFeedback: false, //是否显示校验图标
      copyright: moment().format("YYYY"),
      username: "",
    };
  },
  methods: {
    // 普通登录
    handleLogin(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          this.username = values.username;
          login(values).then((res) => this.handleLoginRes(res));
        }
      });
    },

    handleLoginRes(res) {
      switch (res.code) {
        case 401:
          localStorage.clear();
          location.href = "/login";
          break;
        case 200:
          localStorage.setItem("token", res.data);
          localStorage.setItem("username", this.username);
          this.$router.replace({ name: "site" });
          break;
        default:
          this.$message.error(res.msg);
          break;
      }
    },
    getCaptchaImg() {
      getCaptcha().then((res) => {
        this.captcha = res.data;
      });
    },
  },
  created() {
    this.getCaptchaImg();
  },
};
</script>

<style lang="stylus" scoped>
@import 'login.styl';
</style>
