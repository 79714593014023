import axios from '../utils/axios';

// 获取图形验证码
export function getCaptcha() {
    return axios.get('/captcha')
}

export function login(user) {
    const data = {
        'username': user.username,
        'password': window.btoa(user.password),
        'code': user.code
    }
    return axios.post('/login', data)
}


export function logout(user) {
    console.log("退出登录", user.username)
}
